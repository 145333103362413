import React from "react";
import { Row, Container, Breadcrumb, Card } from "react-bootstrap";

import Layout from "../../components/layout";
import Seo from "../../components/seo";
import { VerifyEmail } from "../../components/accountManagement";

const uri = "/account/verify-email/";

const VerifyEmailPage = ({ location }) => {
  const queryString = require("query-string");

  console.log("**location.search=" + location.search);
  const parsed = queryString.parse(location.search);
  console.log(parsed);
  const token = parsed.token;

  return (
    <Layout pageInfo={{ pageName: "verifyemail" }}>
      <Seo title="Verify Email" keywords={[`verify email`, `login`]} description="Verify Email page" url={uri} />
      <Container fluid>
        <Row className="justify-content-left my-3">
          <Breadcrumb>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="/account/">Account</Breadcrumb.Item>
            <Breadcrumb.Item active>Verify Email</Breadcrumb.Item>
          </Breadcrumb>
        </Row>

        <Row className="d-flex flex-wrap">
          <Card className="account-mgmt-card">
            <Card.Body>
              <VerifyEmail token={token} showLogo={false} />
            </Card.Body>
          </Card>
        </Row>
      </Container>
    </Layout>
  );
};

export default VerifyEmailPage;
